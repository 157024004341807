@import url(https://fonts.googleapis.com/css2?family=Lalezar&display=swap);
/* @font-face {
  font-family: "Lalezar", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
} */

@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir.fd58729f.eot);
  src: url(/static/media/Vazir.fd58729f.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir.a91231a6.woff2) format("woff2"),
    url(/static/media/Vazir.abdaaf2e.woff) format("woff"),
    url(/static/media/Vazir.f5ee3f2f.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir-Bold.86e9c744.eot);
  src: url(/static/media/Vazir-Bold.86e9c744.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir-Bold.2c7bb6b6.woff2) format("woff2"),
    url(/static/media/Vazir-Bold.b0205da6.woff) format("woff"),
    url(/static/media/Vazir-Bold.eda9cf85.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir-Black.d13e8413.eot);
  src: url(/static/media/Vazir-Black.d13e8413.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir-Black.58b9fd18.woff2) format("woff2"),
    url(/static/media/Vazir-Black.059a404e.woff) format("woff"),
    url(/static/media/Vazir-Black.dfa43d45.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir-Medium.d68f6eb0.eot);
  src: url(/static/media/Vazir-Medium.d68f6eb0.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir-Medium.38f9fc2b.woff2) format("woff2"),
    url(/static/media/Vazir-Medium.955e4ed5.woff) format("woff"),
    url(/static/media/Vazir-Medium.4422f8b5.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir-Light.fd376c5b.eot);
  src: url(/static/media/Vazir-Light.fd376c5b.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir-Light.e8c4a74f.woff2) format("woff2"),
    url(/static/media/Vazir-Light.5d36c804.woff) format("woff"),
    url(/static/media/Vazir-Light.ace04414.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Vazir;
  src: url(/static/media/Vazir-Thin.db050bdd.eot);
  src: url(/static/media/Vazir-Thin.db050bdd.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/Vazir-Thin.f7c40971.woff2) format("woff2"),
    url(/static/media/Vazir-Thin.de353b2a.woff) format("woff"),
    url(/static/media/Vazir-Thin.b7ce981c.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Vazir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* , "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */

h1 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h2 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h3 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h4 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h5 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h6 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}

