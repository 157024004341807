@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
h1 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h2 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h3 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h4 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h5 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
h6 {
  color: #3c4858;
  margin: "1.75rem 0 0.875rem";
  text-decoration: none;
  font-weight: 700;
}
